export default [
    {
        title: 'Leave Type',
        key: 'Leave',
        minWidth: 40,
    },

    {
        title: 'Available',
        key: 'Available',
        minWidth: 80,
    },

	{
        title: 'Availed',
        key: 'Availed',
        minWidth: 40,
    },

    
    {
        title: 'Claimable',
        key: 'Claimable',
        minWidth: 80,
    },

]
